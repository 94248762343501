// zagnieżdzony formularz
// każda opcja selekta ma wygenerowany osobny template
// element jest wstawiany w odpowiednim miejscu listy

import BasicController from "./basic_controller";

export default class extends BasicController {
  static targets = [
    "addItem", // select for adding Items; option texts references [data-item-value] attributes
    "items", // items container
    "item", // identified by [data-item-value] attribute
    "template", // template elements for new items, identified by [data-item-value] attribute
  ]

  connect() {
    this.clearSelection();
    $(this.addItemTarget).select2({
      width: 'max-content'
    })
      .off('select2:opening')
      .on('select2:opening', () => setTimeout(this.focusNextOption.bind(this)))
      .off('select2:selecting')
      .on('select2:selecting', this.optionSelecting.bind(this))
  }

  clearSelection() {
    this.addItemTarget.selectedIndex = -1;
  }

  focusNextOption() {
    const lastItem = this.visibleNodes(this.itemTargets).pop()
    if (lastItem) {
      const options = this.addItemTarget.options
      const lastItemValue = lastItem.dataset.itemValue;
      const lastOption = [].find.call(options, option => option.text === lastItemValue)
      const select2 = $(this.addItemTarget).data('select2')
      select2.$dropdown.find(`li:nth-of-type(${(lastOption?.index || 0) + 2})`).trigger('mouseenter');
      select2.results.ensureHighlightVisible()
    }
  }

  optionSelecting(jQueryEvent) {
    jQueryEvent.preventDefault();
    const value = jQueryEvent.params.args.data.text;
    this.addItem(value);
    $(this.addItemTarget).select2('close');
  }

  visibleNodes(nodes) {
    return nodes.filter((node) => node.offsetWidth > 0 && node.offsetHeight > 0)
  }

  addItem(value) {
    const template = this.templateTargets.find((template) => template.dataset.itemValue === value);
    const content = this.replaceItemId(template);
    const matchingItem = this.visibleNodes(this.itemTargets)
      .reverse()
      .find((itemTarget) => {
        return value >= itemTarget.dataset.itemValue;
      });
    if (matchingItem) {
      // insert in the middle or at the end
      matchingItem.insertAdjacentHTML('afterend', content);
      this.afterAddItem(matchingItem.nextElementSibling);
      return matchingItem.nextElementSibling;
    } else {
      // insert at the beginning
      this.itemsTarget.insertAdjacentHTML('afterbegin', content);
      this.afterAddItem(this.itemsTarget.firstChild);
      return this.itemsTarget.firstChild;
    }
  }

  removeItemIfEmpty(event) {
    if (event.target.value.length === 0)
      this.onRemoveItem(event);
  }

  // creates items when nested form is empty for available options from this.addItemTarget
  createItemsForEmptyForm() {
    if (this.itemTargets.length < 1) {
      Array.from(this.addItemTarget.options)
        .slice(0, 100)
        .forEach(el => this.addItem(el.text));
    }
  }
}
