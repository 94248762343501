// controller to set positionInput.value in nested forms
// use in conjuction with nested-form events: after-insert, after-remove

import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [
    "item", // sortable elements
    "positionInput",
    "destroyInput"
  ]

  connect() {
    this.sortable = Sortable.create(this.element, {
      onUpdate: this.setPositions.bind(this),
      handle: '.sortable-handle',
      animation: 150,
    });
  }

  setPositions() {
    this.positionInputTargets
      .filter(positionInput => {  // filter removed positionInputTargets
        const item = this.itemTargets
          .find(item => item.contains(positionInput));
        const destroyInput = this.destroyInputTargets
          .find(destroyInput => item.contains(destroyInput));
        return !destroyInput || destroyInput.value !== '1'
      })
      .forEach((positionInput, i) => {
        positionInput.value = i + 1;
      })
  }
}
